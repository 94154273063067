import { ReactComponent as SvgTwitch } from "../assets/images/social-icons/twitch.svg"
import { ReactComponent as SvgTwitter } from "../assets/images/social-icons/twitter.svg"
import { ReactComponent as SvgYoutube } from "../assets/images/social-icons/youtube.svg"

const socialLinks = [
  {
    title: `Twitch`,
    url: `https://www.twitch.tv/systemicreaction`,
    icon: SvgTwitch,
  },
  {
    title: `Youtube`,
    url: `https://youtube.com/c/SystemicReaction`,
    icon: SvgYoutube,
  },
  {
    title: `Twitter`,
    url: `https://twitter.com/2nd_extinction`,
    icon: SvgTwitter,
  },
]

export default socialLinks
