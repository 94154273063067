import { useState, useEffect, useContext } from "react"
import { useCookies } from "react-cookie"
import { hasWindow } from "../utils"
import { StoreContext } from "../store"

const API_URL = process.env.GATSBY_APEXREF_API_URL
const BASIC_AUTH = process.env.GATSBY_APEXREF_API_AUTH
const APEXREF_CAMPAIGN_ID = process.env.GATSBY_APEXREF_CAMPAIGN_ID
const APEXREF_CAMPAIGN_ID_NEWSLETTER =
  process.env.GATSBY_APEXREF_CAMPAIGN_ID_NEWSLETTER

function useApexRef() {
  const store = useContext(StoreContext)

  const [errors, setErrors] = useState([])
  const [messages, setMessages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [referralData, setReferralData] = useState(null)
  const [authToken, setAuthToken] = useState(null)
  const [cookies, setCookie, removeCookie] = useCookies([`apex-ref-token`])

  useEffect(() => {
    if (cookies[`apex-ref-token`] && !authToken) {
      authenticate(cookies[`apex-ref-token`])
    }
    if (authToken && referralData && !localStorage.getItem(`apex-ref-data`)) {
      setAuthToken(null)
      setReferralData(null)
    }

    if (authToken && !referralData && localStorage.getItem(`apex-ref-data`)) {
      setIsLoading(true)
      setReferralData(JSON.parse(localStorage.getItem(`apex-ref-data`)))
      store.setStatus(`loggedIn`)
      localStorage.setItem(`apex-ref-status`, `loggedIn`)
      setIsLoading(false)
    }

    if (authToken && !referralData && !isLoading) {
      getReferralData()
    }

    async function getReferralData() {
      if (errors.length > 0 || referralData || !authToken || isLoading) {
        return null
      }

      setIsLoading(true)

      const response = await fetch(`${API_URL}/signups?campaign_info=true`, {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Basic ${BASIC_AUTH}`,
          "X-TOKEN": authToken,
        },
      }).catch(() => {
        setIsLoading(false)
        setErrors([`did_not_reach_server`])
      })

      if (response && response.status < 500) {
        const json = await response.json()

        if (json.status === `error`) {
          removeCookie(`apex-ref-token`)

          setAuthToken(null)
          setErrors([`expired_token`])
          setIsLoading(false)

          return null
        }

        setCookie(`apex-ref-token`, authToken, {
          path: `/`,
          maxAge: 604800, // 1 week
          secure: true,
          sameSite: `strict`,
        })
        localStorage.setItem(`apex-ref-data`, JSON.stringify(json.data))
        setIsLoading(false)
        store.setStatus(`loggedIn`)
        localStorage.setItem(`apex-ref-status`, `loggedIn`)

        return null
      }

      setIsLoading(false)
      setErrors([`did_not_reach_server`])

      return null
    }
  })

  function authenticate(token) {
    if (!isLoading && !authToken) {
      setAuthToken(token)

      if (hasWindow) {
        window.history.replaceState(
          {},
          window.location.origin,
          window.location.pathname
        )
      }
    }
  }

  const formikUpdateProfile = async values => {
    const bodyObject = {
      newsletter: values.newsletter,
      enable_referral_notifications: values.enable_referral_notifications,
    }

    setIsLoading(true)
    const response = await fetch(`${API_URL}/signups`, {
      method: `PATCH`,
      headers: {
        "Content-Type": `application/json`,
        Authorization: `Basic ${BASIC_AUTH}`,
        "X-TOKEN": authToken,
      },
      body: JSON.stringify(bodyObject),
    }).catch(() => {
      setIsLoading(false)
      setErrors([`did_not_reach_server`])
    })

    if (response) {
      const json = await response.json()

      if (json.status === `error`) {
        setMessages([])
        return setErrors(json.messages)
      }

      if (json.status === `ok`) {
        setErrors([])
        setMessages([json.message])
      }
    }

    setIsLoading(false)
    return values
  }

  function signOut() {
    setIsLoading(true)
    removeCookie(`apex-ref-token`, {
      path: `/`,
    })
    localStorage.removeItem(`apex-ref-data`)
    localStorage.setItem(`apex-ref-status`, `anonymous`)
    store.setStatus(`anonymous`)
    setIsLoading(false)
  }

  function clearErrors() {
    setErrors([])
  }

  return {
    isLoading,
    referralData,
    authenticate,
    signOut,
    formikUpdateProfile,
    errors,
    messages,
    clearErrors,
  }
}

function useApexRefSignUpForm() {
  const store = useContext(StoreContext)

  const [fields, setFields] = useState({
    email: ``,
    referalCode: ``,
    newsletter: true,
  })
  const [errors, setErrors] = useState([])
  const [messages, setMessages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [referralCode, setReferralCode] = useState(``)
  const [cookies, setCookie, removeCookie] = useCookies([`apex-ref-referral`])

  const formikHandleSubmit = async values => {
    clearErrors()
    setIsLoading(true)

    const referralCode = cookies[`apex-ref-referral`] || values.referralCode

    const bodyObject = {
      campaign_id:
        values.campaign === `newsletter`
          ? APEXREF_CAMPAIGN_ID_NEWSLETTER
          : APEXREF_CAMPAIGN_ID,
      email: values.email,
      newsletter: true,
      enable_referral_notifications: true,
      referral_code: referralCode,
      metadata: [],
    }

    return signupRequest(bodyObject)
  }

  const signupRequest = async bodyObject => {
    const response = await fetch(`${API_URL}/signups`, {
      method: `POST`,
      headers: {
        "Content-Type": `application/json`,
        Authorization: `Basic ${BASIC_AUTH}`,
      },
      body: JSON.stringify(bodyObject),
    }).catch(() => {
      setErrors([`did_not_reach_server`])
      setIsLoading(false)
    })

    if (response) {
      const json = await response.json()

      if (json.status === `error`) {
        setErrors(json.messages)
        setIsLoading(false)
        return json
      }

      if (json.status === `ok`) {
        if (json.message.includes(`record_created`)) {
          store.setStatus(`newAccount`)
          localStorage.setItem(`apex-ref-status`, `newAccount`)
        }
        if (json.message.includes(`auth_token_sent`)) {
          store.setStatus(`oldAccount`)
          localStorage.setItem(`apex-ref-status`, `oldAccount`)
        }
        setMessages([json.message])
      }

      removeCookie(`apex-ref-referral`)

      setIsLoading(false)

      return json
    }

    setIsLoading(false)

    return true
  }

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault()

      setIsLoading(true)
      clearErrors()

      const referralCode = cookies[`apex-ref-referral`] || fields.referralCode

      const bodyObject = {
        campaign_id:
          fields.campaign === `newsletter`
            ? APEXREF_CAMPAIGN_ID_NEWSLETTER
            : APEXREF_CAMPAIGN_ID,
        email: fields.email,
        newsletter: true,
        enable_referral_notifications: true,
        referral_code: referralCode,
        metadata: [],
      }

      return signupRequest(bodyObject)
    }

    return null
  }

  function clearErrors() {
    setErrors([])
  }

  const handleFieldChange = event => {
    event.persist()

    setFields(fields => ({
      ...fields,
      [event.target.name]: event.target.value,
    }))
  }

  const setReferralCodeCookie = referralCode => {
    setCookie(`apex-ref-referral`, referralCode, {
      path: `/`,
      maxAge: 21600, // 6 hours, set in seconds
      // secure: true,
      sameSite: `strict`,
    })
    setReferralCode(referralCode)
  }

  return {
    referralCode,
    setReferralCode,
    setReferralCodeCookie,
    isLoading,
    formikHandleSubmit,
    handleSubmit,
    handleFieldChange,
    fields,
    errors,
    messages,
  }
}

export { useApexRef, useApexRefSignUpForm }
