import React from "react"
import ReactModal from "react-modal"
import { RemoveScroll } from "react-remove-scroll"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import { ReactComponent as SvgDelete } from "../assets/images/icons/delete.svg"

ReactModal.setAppElement(`body`)

const ReactModalStyled = styled(ReactModal)`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: ${props => props.theme.ziModal};
  top: 0;
  left: 0;
  color: ${props => props.theme.colorWhite};
  background-color: rgba(0, 0, 0, 0.9);
  animation: ${props => props.theme.fadeIn} 0.4s
    ${props => props.theme.easingDefault};
`

const RemoveScrollStyled = styled(RemoveScroll)`
  width: 100%;
  height: 100%;
`

const Close = styled.div`
  position: absolute;
  z-index: 2;
  top: ${rem(15)};
  right: ${rem(15)};

  button {
    width: ${rem(40)};
    height: ${rem(40)};
    padding: ${rem(10)};
    display: block;
    transition: 0.2s ${props => props.theme.easingDefault};
    transition-property: transform, opacity;

    &:hover {
      transform: scale(1.2);
    }

    &:active {
      opacity: 0.5;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`

const Modal = ({ children, label, onRequestClose }) => {
  const contentClick = e => {
    if (e.target === e.currentTarget) {
      onRequestClose()
    }
  }

  return (
    <ReactModalStyled
      defaultStyles={{}}
      isOpen={true}
      onRequestClose={onRequestClose}
      contentLabel={label}
      // ariaHideApp={false}
    >
      <RemoveScrollStyled>
        <Close>
          <button
            type="button"
            aria-label="Close"
            title="Close"
            onClick={() => onRequestClose()}
          >
            <SvgDelete aria-label="true" />
          </button>
        </Close>

        <Content onClick={contentClick}>{children}</Content>
      </RemoveScrollStyled>
    </ReactModalStyled>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
}

export default Modal
