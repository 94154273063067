import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import { useStaticQuery, graphql } from "gatsby"
import { StoreContext } from "../store"
import { useApexRef } from "../hooks/apex-ref-hooks"

import Modal from "./modal"
import Link from "./link"
import Button from "./button"
import socialLinks from "../config/social-links"

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const Inner = styled.div`
  ${props => props.theme.gridContainer()}

  width: 100%;
  height: 100%;
  padding-top: ${rem(15)};
  padding-bottom: ${rem(30)};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const Nav = styled.nav`
  ${props => props.theme.ffSecondarySet()}

  font-size: ${rem(60)};

  a {
    display: block;
    line-height: 1;

    &:first-child {
      width: calc(100% - 50px);
    }

    &:hover {
      color: ${props => props.theme.colorYellow};
    }

    &.\\--active {
      color: ${props => props.theme.colorYellow};
    }
  }
`

const Cta = styled.div`
  margin-top: ${rem(20)};

  > * + * {
    margin-top: ${rem(10)};
  }

  a {
    width: 100%;
  }
`

const Social = styled.div`
  margin-top: auto;
  padding-top: ${rem(40)};
  text-align: center;

  a {
    width: ${rem(25)};
    height: ${rem(25)};
    display: inline-block;
    margin: ${rem(5)} ${rem(10)};

    &:hover {
      svg {
        transform: scale(1.1);
      }
    }

    &:active {
      svg {
        opacity: 0.6;
      }
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
    transition: 0.2s ${props => props.theme.easingDefault};
    transition-property: transform, opacity;
  }
`

const NavModal = ({ onRequestClose }) => {
  const {
    headerOptions: {
      options: { menu, cta },
    },
  } = useStaticQuery(graphql`
    query {
      ...HeaderOptionsFragment
      ...MetaWpOptionsFragment
    }
  `)

  const { signOut } = useApexRef()
  const store = useContext(StoreContext)

  return (
    <Modal onRequestClose={onRequestClose}>
      <Container>
        <Inner>
          {menu && menu.length > 0 && (
            <Nav>
              <Link
                to="/"
                data-track-click
                data-track-click-ga-category="Mobile Header"
                data-track-click-ga-action="Nav Click"
                data-track-click-ga-label="Home"
              >
                Home
              </Link>
              {menu.map((item, i) => (
                <Link
                  key={i}
                  to={item.url}
                  className={item.cssClassNames}
                  activeClassName="--active"
                  onClick={() => onRequestClose()}
                  data-track-click
                  data-track-click-ga-category="Mobile Header"
                  data-track-click-ga-action="Nav Click"
                  data-track-click-ga-label={item.title}
                >
                  {item.title}
                </Link>
              ))}
            </Nav>
          )}

          {cta && cta.length > 0 && (
            <Cta>
              {cta.map((item, i) => (
                <Button
                  key={i}
                  as={Link}
                  to={item.url}
                  data-track-click
                  data-track-click-ga-category="Mobile Header"
                  data-track-click-ga-action="CTA Click"
                  data-track-click-ga-label={
                    store.status === `loggedIn`
                      ? item.loggedInTitle
                      : item.title
                  }
                >
                  <span>{item.title}</span>
                </Button>
              ))}

              {store.status === `loggedIn` && (
                <Button
                  as={Link}
                  to={`/`}
                  onClick={() => signOut()}
                  color={`black`}
                  style={{ marginLeft: `-5px` }}
                  data-track-click
                  data-track-click-ga-category="Mobile Header"
                  data-track-click-ga-action="Sign Out Click"
                  data-track-click-ga-label="Sign out"
                >
                  <span>Sign out</span>
                </Button>
              )}
            </Cta>
          )}

          {socialLinks && socialLinks.length > 0 && (
            <Social>
              {socialLinks.map((item, i) => (
                <Link
                  key={i}
                  to={item.url}
                  title={item.title}
                  aria-label={item.title}
                  data-track-click
                  data-track-click-ga-category="Mobile Header"
                  data-track-click-ga-action="Social Link Click"
                  data-track-click-ga-label={item.title}
                >
                  {item.icon()}
                </Link>
              ))}
            </Social>
          )}
        </Inner>
      </Container>
    </Modal>
  )
}

NavModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
}

export default NavModal
