import React, { useState, useContext } from "react"
import styled, { css } from "styled-components"
import { useApexRef } from "../hooks/apex-ref-hooks"
import { rem, rgba } from "polished"
import { useStaticQuery, graphql } from "gatsby"
import { StoreContext } from "../store"

import Link from "./link"
import NavModal from "./nav-modal"
import socialLinks from "../config/social-links"
import imgLogo from "../assets/images/logo.png"
import { ReactComponent as SvgMenu } from "../assets/images/icons/menu.svg"
import { ReactComponent as SvgPc } from "../assets/images/console-logos/pc.svg"
import { ReactComponent as SvgPs4 } from "../assets/images/console-logos/ps4.svg"
import { ReactComponent as SvgXbox } from "../assets/images/console-logos/xbox.svg"

const Container = styled.header`
  ${props => props.theme.gridContainer()}

  padding-top: ${rem(30)};
  padding-bottom: ${rem(30)};
  display: flex;
  align-items: center;
  position: relative;

  @media ${props => props.theme.xlargeDown} {
    padding-top: ${rem(15)};
    padding-bottom: ${rem(15)};
  }
`

const Logo = styled.div`
  margin-right: ${rem(40)};

  a {
    display: block;
  }

  img {
    width: ${rem(195)};
    display: block;

    @media ${props => props.theme.xxlargeDown} {
      width: ${rem(140)};
    }
  }
`

const Nav = styled.div`
  /* margin-right: ${rem(40)}; */
  flex-grow: 1;
  padding: ${rem(5)};
  clip-path: polygon(
    ${rem(10)} 0%,
    100% 0%,
    calc(100% - ${rem(10)}) 100%,
    0% 100%
  );
  background-color: rgba(0, 0, 0, 0.2);

  @media ${props => props.theme.xlargeDown} {
    display: none;
  }

  > div {
    height: ${rem(50)};
    border: solid rgba(255, 255, 255, 0.15);
    border-width: 1px 0;
    display: flex;
    clip-path: polygon(
      ${rem(8)} 0%,
      100% 0%,
      calc(100% - ${rem(8)}) 100%,
      0% 100%
    );
    background-color: ${props => props.theme.colorBlack};
    background-size: 6px 6px;
    // 50% opacity dots background
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNCAxNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTQgMTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7b3BhY2l0eTowLjU7fQoJLnN0MXtmaWxsOiMyNjI2MjY7fQo8L3N0eWxlPgo8ZyBjbGFzcz0ic3QwIj4KCTxjaXJjbGUgY2xhc3M9InN0MSIgY3g9IjIuNSIgY3k9IjIuNSIgcj0iMi41Ii8+CjwvZz4KPGcgY2xhc3M9InN0MCI+Cgk8Y2lyY2xlIGNsYXNzPSJzdDEiIGN4PSI5LjUiIGN5PSI5LjUiIHI9IjIuNSIvPgo8L2c+Cjwvc3ZnPgo=");
  }
`

const NavGroup = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  font-size: ${rem(20)};

  &::before {
    content: "";
    width: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${rem(4)};
    transform: rotate(12deg);
    background-color: rgba(255, 255, 255, 0.15);
  }

  a {
    margin-top: -1px;
    margin-bottom: -1px;
    margin-right: ${rem(-8)};
    padding: 0 ${rem(20)};
    padding-top: ${rem(5)};
    display: flex;
    align-items: center;
    position: relative;
    clip-path: polygon(
      ${rem(8)} 0%,
      100% 0%,
      calc(100% - ${rem(8)}) 100%,
      0% 100%
    );
    outline-offset: -0.5em;
    border: solid transparent;
    border-width: 2px 0;
    transition: 0.2s ${props => props.theme.easingDefault};
    transition-property: color, background-color, border-bottom-color;

    &:hover,
    &.\\--active {
      background-color: rgba(255, 255, 255, 0.1);
      color: ${props => props.theme.colorYellow};
    }

    &.\\--active {
      border-bottom-color: currentColor;
    }

    &::after {
      content: "";
      width: 1px;
      position: absolute;
      top: ${rem(-4)};
      bottom: ${rem(-4)};
      right: ${rem(4)};
      transform: rotate(12deg);
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
`

const NavPages = styled.nav`
  ${props => props.theme.ffSecondarySet()}
`

const NavSocial = styled.div`
  margin-left: auto;

  a {
    padding-top: 0;
  }

  svg {
    width: 0.8em;
    height: 0.8em;
    display: block;
  }
`

const Button = styled.a`
  ${props => props.theme.ffSecondarySet()}

  --text-color: ${props => props.theme.colorGreyDarkest};

  margin: -1px 0;
  display: flex;
  padding: ${rem(4)};
  clip-path: polygon(
    ${rem(8)} 0%,
    100% 0%,
    calc(100% - ${rem(8)}) 100%,
    0% 100%
  );
  font-size: ${rem(18)};
  color: var(--text-color);
  background-color: ${props => rgba(props.theme.colorOrange, 0.6)};
  outline-offset: -1em;

  &:hover {
    > span {
      background-color: ${props => props.theme.colorYellow};
    }
  }

  > span {
    padding: 0 ${rem(20)};
    padding-top: ${rem(5)};
    display: flex;
    align-items: center;
    clip-path: polygon(
      ${rem(6)} 0%,
      100% 0%,
      calc(100% - ${rem(6)}) 100%,
      0% 100%
    );
    box-shadow: inset 0 0 0.8em 0.1em ${props => props.theme.colorYellow};
    background-color: ${props => props.theme.colorOrange};
    transition: background-color 0.2s ${props => props.theme.easingDefault};
  }

  ${props =>
    props.color === `black` &&
    css`
      --text-color: ${props => props.theme.colorYellow};

      background-color: ${props => props.theme.colorBlack};

      &:hover {
        > span {
          background-color: ${props => props.theme.colorGreyDarkest};
        }
      }

      > span {
        background-color: ${props => props.theme.colorBlack};
        box-shadow: inset 0 0 0.8em 0.1em ${props => props.theme.colorBlack};
      }
    `}
`

const Consoles = styled.div`
  min-width: ${rem(100)};

  @media ${props => props.theme.xlargeDown} {
    display: none;
  }

  &::before {
    content: "";
    width: 100%;
    height: ${rem(25)};
    margin-left: ${rem(-20)};
    position: absolute;
    top: 0;
    clip-path: polygon(0% 0%, 100% 0, 100% 100%, ${rem(20)} 100%);
    background-color: ${props => props.theme.colorGreyDarkest};
  }

  span {
    display: block;
    font-size: ${rem(10)};
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  ul {
    display: flex;
    align-items: center;
  }

  li {
    &:not(:last-child) {
      margin-right: ${rem(10)};
    }
  }

  svg {
    &.\\--pc {
      width: ${rem(27)};
      height: ${rem(12)};
    }

    &.\\--ps {
      width: ${rem(78)};
      height: ${rem(17)};
    }

    &.\\--xbox {
      width: ${rem(57)};
      height: ${rem(18)};
    }
  }
`

const NavModalToggle = styled.button.attrs({ type: `button` })`
  width: ${rem(40)};
  height: ${rem(40)};
  margin-left: auto;
  padding: ${rem(7)};
  background-color: rgba(0, 0, 0, 0.5);
  clip-path: polygon(
    ${rem(6)} 0%,
    100% 0%,
    calc(100% - ${rem(6)}) 100%,
    0% 100%
  );
  position: fixed;
  top: ${rem(10)};
  right: ${rem(10)};
  z-index: 100000;

  @media ${props => props.theme.xlargeUp} {
    display: none;
  }

  &:hover {
    svg {
      transform: scale(1.1);
    }
  }

  &:active {
    svg {
      opacity: 0.6;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
    clip-path: polygon(
      ${rem(4)} 0%,
      100% 0%,
      calc(100% - ${rem(4)}) 100%,
      0% 100%
    );
    transition: 0.2s ${props => props.theme.easingDefault};
    transition-property: transform, opacity;
  }
`

const Header = () => {
  const {
    headerOptions: {
      options: { menu, cta },
    },
    metaWpOptions: {
      options: { name: siteName },
    },
  } = useStaticQuery(graphql`
    query {
      ...HeaderOptionsFragment
      ...MetaWpOptionsFragment
    }
  `)

  const [displayNavModal, setDisplayNavModal] = useState(false)
  const { signOut } = useApexRef()
  const store = useContext(StoreContext)

  return (
    <Container>
      <Logo>
        <Link
          to="/"
          data-track-click
          data-track-click-ga-category="Header"
          data-track-click-ga-action="Logo Click"
        >
          <img src={imgLogo} alt={siteName} />
        </Link>
      </Logo>

      <Nav>
        <div>
          {menu && menu.length > 0 && (
            <NavGroup as={NavPages}>
              {menu.map((item, i) => (
                <Link
                  key={i}
                  to={item.url}
                  className={item.cssClassNames}
                  activeClassName="--active"
                  data-track-click
                  data-track-click-ga-category="Header"
                  data-track-click-ga-action="Nav Click"
                  data-track-click-ga-label={item.title}
                >
                  {item.title}
                </Link>
              ))}
            </NavGroup>
          )}

          {socialLinks && socialLinks.length > 0 && (
            <NavGroup as={NavSocial}>
              {socialLinks.map((item, i) => (
                <Link
                  key={i}
                  to={item.url}
                  title={item.title}
                  aria-label={item.title}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-track-click
                  data-track-click-ga-category="Header"
                  data-track-click-ga-action="Social Link Click"
                  data-track-click-ga-label={item.title}
                >
                  {item.icon()}
                </Link>
              ))}
            </NavGroup>
          )}

          {cta && cta.length > 0 && (
            <React.Fragment>
              {cta.map((item, i) => (
                <Button
                  data-track-click
                  data-track-click-ga-category="Header"
                  data-track-click-ga-action="CTA Click"
                  data-track-click-ga-label={
                    store.status === `loggedIn`
                      ? item.loggedInTitle
                      : item.title
                  }
                  key={i}
                  as={Link}
                  to={item.url}
                >
                  <span>
                    {store.status === `loggedIn`
                      ? item.loggedInTitle
                      : item.title}
                  </span>
                </Button>
              ))}

              {store.status === `loggedIn` && (
                <Button
                  as={Link}
                  to={`/`}
                  onClick={() => signOut()}
                  color={`black`}
                  style={{ marginLeft: `-5px` }}
                  data-track-click
                  data-track-click-ga-category="Header"
                  data-track-click-ga-action="Sign Out Click"
                  data-track-click-ga-label="Sign out"
                >
                  <span>Sign out</span>
                </Button>
              )}
            </React.Fragment>
          )}
        </div>
      </Nav>

      {false && (
        <Consoles>
          <span>Available soon for</span>

          <ul>
            <li>
              <SvgPc className="--pc" />
            </li>

            <li>
              <SvgPs4 className="--ps" />
            </li>

            <li>
              <SvgXbox className="--xbox" />
            </li>
          </ul>
        </Consoles>
      )}

      <NavModalToggle
        onClick={() => setDisplayNavModal(true)}
        aria-label="Show menu"
        title="Show menu"
      >
        <SvgMenu aria-hidden="true" />
      </NavModalToggle>

      {displayNavModal && (
        <NavModal
          onRequestClose={() => {
            setTimeout(() => setDisplayNavModal(false), 750)
          }}
        />
      )}
    </Container>
  )
}

export default Header
